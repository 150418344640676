import updateBackgroundClasses from './updateBackgroundClasses';
import updateSectionActiveState from './updateSectionActiveState';
import updateContentGroups from './updateContentGroups';
import updateCards from './updateCards';

export default function scrollFade() {
	const sections = document.querySelectorAll('section');

	if (sections.length === 0) return;

	const onScroll = () => {
		sections.forEach((section, index) => {
			const rect = section.getBoundingClientRect();
			updateBackgroundClasses(index, rect);
			updateSectionActiveState(section, index, rect);
		});

		updateContentGroups();
		updateCards();
	};

	window.addEventListener('scroll', onScroll);

	// Initial check in case sections are already in view
	onScroll();
}
