const customCursor = () => {
	document.addEventListener('DOMContentLoaded', () => {
		const cards = document.querySelectorAll('.card-container');
		const hoverBackground = document.createElement('div');
		hoverBackground.classList.add('hover-background');
		document.body.appendChild(hoverBackground);

		const cardsContainer = document.querySelector('.cards-container');
		let activeBackground = null;

		cards.forEach((card) => {
			card.addEventListener('mouseenter', () => {
				const backgroundUrl = card.getAttribute('data-background');
				if (backgroundUrl) {
					hoverBackground.style.backgroundImage = `url(${backgroundUrl})`;
					hoverBackground.classList.add('active');
					activeBackground = backgroundUrl;
				}
			});

			card.addEventListener('mousemove', (e) => {
				hoverBackground.style.top = `${e.pageY}px`;
				hoverBackground.style.left = `${e.pageX}px`;
			});
		});

		cardsContainer.addEventListener('mouseleave', () => {
			hoverBackground.classList.remove('active');
			activeBackground = null;
		});

		cardsContainer.addEventListener('mousemove', (e) => {
			if (activeBackground) {
				hoverBackground.style.top = `${e.pageY}px`;
				hoverBackground.style.left = `${e.pageX}px`;
			}
		});
	});
};

export default customCursor;
