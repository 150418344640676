const smoothScroll = () => {
	document.addEventListener('DOMContentLoaded', function () {
		const smoothScrollLinks = document.querySelectorAll('a[href^="#"]');

		smoothScrollLinks.forEach((link) => {
			link.addEventListener('click', function (event) {
				event.preventDefault();
				const targetId = this.getAttribute('href').slice(1);
				const targetElement = document.getElementById(targetId);

				if (targetElement) {
					targetElement.scrollIntoView({
						behavior: 'smooth',
					});
				}
			});
		});
	});
};

export default smoothScroll;
