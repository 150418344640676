function initHideNavOnScroll() {
	const header = document.querySelector('header');
	const pageTitle = document.title;

	document.addEventListener('visibilitychange', () => {
		if (document.hidden) {
			document.title = 'Come back!';
		} else {
			document.title = pageTitle;
		}
	});

	if (!header) return;

	let lastScrollPosition = 0;

	const onScroll = () => {
		const currentScrollPosition = window.scrollY;

		if (currentScrollPosition === 0) {
			header.classList.add('initial');
		} else {
			header.classList.remove('initial');
		}

		if (currentScrollPosition > lastScrollPosition) {
			// Scrolling down
			header.classList.add('hidden');
		} else {
			// Scrolling up
			header.classList.remove('hidden');
		}

		lastScrollPosition = currentScrollPosition;
	};

	window.addEventListener('scroll', onScroll);
	previousScrollY = scrollY;
}

const nav = () => {
	document.addEventListener('DOMContentLoaded', function () {
		const menuBtn = document.querySelector('.menu-btn');
		const menu = document.querySelector('nav.mobile');

		console.log(menuBtn);

		const currentScrollPosition = window.scrollY;

		if (currentScrollPosition === 0) {
			header.classList.add('initial');
		} else {
			header.classList.remove('initial');
		}

		menu.addEventListener('click', function () {
			console.log('test');
			menu.classList.toggle('open');
		});

		initHideNavOnScroll();
	});
};

// hideNavOnScroll.js

export default nav;
