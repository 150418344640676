const sections = document.querySelectorAll('section');

const updateSectionActiveState = (section, index, rect) => {
	switch (index) {
		case 1:
			if (rect.top <= 500) {
				section.classList.add('active');
			} else {
				section.classList.remove('active');
			}
			break;
		case 2:
			if (rect.top <= 500) {
				sections[1].classList.remove('active');
				section.classList.add('active');
			} else {
				section.classList.remove('active');
			}
			break;
		case 3:
			if (rect.top <= 300 || rect.bottom <= 10) {
				sections[2].classList.remove('active');
				section.classList.add('active');
			} else {
				section.classList.remove('active');
			}
			break;
		case 4:
			if (rect.top <= 300 || rect.bottom <= 10) {
				sections[3].classList.remove('active');
				section.classList.add('active');
			} else {
				section.classList.remove('active');
			}
			break;
	}
};

export default updateSectionActiveState;
