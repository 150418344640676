const mainBackgroundContainer = document.querySelector(
	'.main-background-container'
);

const updateBackgroundClasses = (index, rect) => {
	switch (index) {
		case 0: {
			if (rect.top <= 0) {
				mainBackgroundContainer.classList.add('black');
			}
			break;
		}
		case 2:
			if (rect.top <= 500) {
				mainBackgroundContainer.classList.add('yellow');
			} else {
				mainBackgroundContainer.classList.remove('yellow');
			}
			break;
		case 3:
			if (rect.top <= 300) {
				mainBackgroundContainer.classList.remove('yellow');
				mainBackgroundContainer.classList.add('black');
			} else {
				mainBackgroundContainer.classList.remove('black');
			}
		case 4:
			if (rect.top <= 300) {
				mainBackgroundContainer.classList.remove('black');
				mainBackgroundContainer.classList.add('white');
			} else {
				mainBackgroundContainer.classList.remove('white');
			}
	}
};

export default updateBackgroundClasses;
