// parallax.js
export default function initParallax() {
	const images = document.querySelectorAll('.multiple-images-container img');

	if (images.length === 0) return;

	const parallaxEffect = () => {
		const scrollPosition = window.scrollY;

		images.forEach((image) => {
			const speed = parseFloat(image.dataset.speed) || 0.3; // Default speed is 0.3 if not set
			const direction = image.dataset.direction || 'down'; // Default direction is "down"
			const offset = image.offsetTop;

			// Calculate the parallax movement
			let parallaxY = (scrollPosition - offset) * speed;

			// Reverse the movement if direction is "up"
			if (direction === 'up') {
				parallaxY = -parallaxY;
			}

			image.style.transform = `translateY(${parallaxY}px)`;
		});
	};

	window.addEventListener('scroll', parallaxEffect);
}
