const contentGroups = Array.from(document.querySelectorAll('.content-group'));
const imageGroups = document.querySelectorAll('.image-group');

const updateContentGroups = () => {
	let activeContentIndex = 0;
	let activeMobileContentIndex = 0;

	imageGroups.forEach((imageGroup, index) => {
		const rect = imageGroup.getBoundingClientRect();
		if (index === 0) {
			contentGroups[0].classList.add('active');
		} else if (index % 2 == 0 && rect.top <= 0) {
			activeMobileContentIndex = index / 2;
		} else {
			contentGroups[0].classList.remove('active');
		}
	});

	imageGroups.forEach((imageGroup, index) => {
		if (activeMobileContentIndex === index) {
			contentGroups[index].classList.add('active');
		} else {
			if (contentGroups[index]) {
				contentGroups[index].classList.remove('active');
			}
		}
	});
};

export default updateContentGroups;
