const updateCards = () => {
	const cards = document.querySelectorAll('.card-container');

	cards.forEach((card) => {
		const rect = card.getBoundingClientRect();

		// Add `fade-in` when the top of the element is above or at the bottom of the viewport
		if (rect.top <= window.innerHeight) {
			card.classList.add('fade-in');
		} else {
			// Remove `fade-in` when the element is entirely below the bottom of the viewport
			card.classList.remove('fade-in');
		}

		// Add `fade-out` when the element's top is near the top of the viewport
		if (rect.top <= 50) {
			card.classList.add('fade-out');
		} else {
			card.classList.remove('fade-out');
		}
	});
};

export default updateCards;
